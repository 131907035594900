<template>
  <div class="w-100">
    <Addresses />
  </div>
</template>

<script>
import Addresses from '@/components/profile/settings/addresses/Index.vue'
export default {
  name: 'AddressesPage',
  components: { Addresses }
}
</script>
