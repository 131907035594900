<template>
  <div class="addresses">
    <transition name="fade">
      <BaseModal
        v-if="openModal"
        :title="$t('account.newAddress')"
        @close="hideModalPopup"
      >
        <form>
          <BaseTextInput
            :value="form.street"
            input-id="street"
            class="m-t-18"
            type="street"
            :label="$t('forms.oldPassword')"
            :has-error="errors.has('street')"
            :message="errors.get('street')"
            @reset="errors.clear()"
            @submit="validateData"
          />
        </form>
      </BaseModal>
    </transition>
    <div
      class="header d-flex justify-content-between align-items-center m-b-22"
    >
      <h3 class="bold-font fs-20 black-color-text">
        العناوين
      </h3>
      <base-button
        :text="$t('account.newAddress')"
        bg-color-class="main-color-bg"
        fixed-width="170px"
        is-medium
        @click.native="showModalPopup"
      />
    </div>

    <div
      class="address d-flex justify-content-between align-items-center radius-14 p-y-20 p-x-16 m-b-18"
    >
      <div
        class="d-flex justify-content-start align-items-center gap-18 address-content"
      >
        <home-icon class="black-color-fill-svg" />
        <p class="medium-font fs-16 dark-gray-color-text">
          {{ $t('account.address') }}
        </p>
      </div>

      <div class="actions d-flex justify-content-end align-items-center gap-14">
        <base-button
          :text="$t('account.editAddress')"
          fixed-width="150px"
          bg-color-class="dark-gray-color-bg"
          is-medium
        />

        <base-button
          :text="$t('account.deleteAddress')"
          fixed-width="150px"
          bg-color-class="white-color-bg"
          color-class="red-color-text"
          is-medium
        />
      </div>
    </div>

    <div
      class="address d-flex justify-content-between align-items-center radius-14 p-y-20 p-x-16 m-b-18"
    >
      <div
        class="d-flex justify-content-start align-items-center gap-18 address-content"
      >
        <home-icon class="black-color-fill-svg" />
        <p class="medium-font fs-16 dark-gray-color-text">
          {{ $t('account.address') }}
        </p>
      </div>

      <div class="actions d-flex justify-content-end align-items-center gap-14">
        <base-button
          :text="$t('account.editAddress')"
          fixed-width="150px"
          bg-color-class="dark-gray-color-bg"
          is-medium
        />

        <base-button
          text="مسح العنوان"
          fixed-width="150px"
          bg-color-class="white-color-bg"
          color-class="red-color-text"
          is-medium
        />
      </div>
    </div>

    <div
      class="address d-flex justify-content-between align-items-center radius-14 p-y-20 p-x-16 m-b-18"
    >
      <div
        class="d-flex justify-content-start align-items-center gap-18 address-content"
      >
        <home-icon class="black-color-fill-svg" />
        <p class="medium-font fs-16 dark-gray-color-text">
          المبنى : ٢ج ,الشارع : 199الدور : 8الشقة : 18 ,المعادي - القاهرة
        </p>
      </div>

      <div class="actions d-flex justify-content-end align-items-center gap-14">
        <base-button
          text="تعديل العنوان"
          fixed-width="150px"
          bg-color-class="dark-gray-color-bg"
          is-medium
        />

        <base-button
          text="مسح العنوان"
          fixed-width="150px"
          bg-color-class="white-color-bg"
          color-class="red-color-text"
          is-medium
        />
      </div>
    </div>

    <div
      class="address d-flex justify-content-between align-items-center radius-14 p-y-20 p-x-16 m-b-18"
    >
      <div
        class="d-flex justify-content-start align-items-center gap-18 address-content"
      >
        <home-icon class="black-color-fill-svg" />
        <p class="medium-font fs-16 dark-gray-color-text">
          المبنى : ٢ج ,الشارع : 199الدور : 8الشقة : 18 ,المعادي - القاهرة
        </p>
      </div>

      <div class="actions d-flex justify-content-end align-items-center gap-14">
        <base-button
          text="تعديل العنوان"
          fixed-width="150px"
          bg-color-class="dark-gray-color-bg"
          is-medium
        />

        <base-button
          text="مسح العنوان"
          fixed-width="150px"
          bg-color-class="white-color-bg"
          color-class="red-color-text"
          is-medium
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/partials/base-modal/Index.vue'
import BaseTextInput from '@/components/partials/base-text-input/Index.vue'
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import { modalMixin } from '../../../../mixins/modal'

export default {
  name: 'Addresses',
  components: { BaseModal, BaseTextInput },
  mixins: [modalMixin],

  data () {
    return {
      addressPopup: false,
      form: new Form({
        street: ''
      }),

      errors: new Errors()
    }
  },

  methods: {
    validateData () {}
  }
}
</script>

<style lang="scss" scoped>
@import './addresses';
</style>
