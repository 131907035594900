export const modalMixin = {
  methods: {
    showModalPopup () {
      this.openModal = true
    },

    hideModalPopup () {
      this.openModal = false
    }
  }
}
